import React, { useState, useContext, useEffect, useMemo } from "react";
import "../Header/HeaderNav.css";
import StateContext from "../../context/stateGlobal/StateContext";
import axios from "axios";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import { Link, useParams, useNavigate } from "react-router-dom";
import Login from "./Login";
import DownloadReport from "../DownloadReport/DownloadReport";
import HomeIcon from '@mui/icons-material/Home';

const cookies = new Cookies();
export default function HeaderNavRight({ setToggleBtnMenu, appDivRef }) {
	let publicUrl = process.env.PUBLIC_URL;
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const globalState = useContext(StateContext);
	const [showMessage, setShowMessage] = useState(false);
	// const [userName, setUserName] = useState("");
	const [shareUrl, setShareUrl] = useState("");
	const [showLogin, setShowLogin] = useState(false);
	const [patientName, setPatientName] = useState("");
	const [patientLowerData, setPatientLowerData] = useState(null);
	const [patientUpperData, setPatientUpperData] = useState(null);
	const [patientIPRData, setPatientIPRData] = useState(false);
	const [patientIPRJsonData, setPatientIPRJsonData] = useState([]);
	const [doctorName, setDoctorName] = useState(null);
	const [helpFormData, setHelpFormData] = useState({
		message: "",
		mobileNumber: "",
	});

	const navigate = useNavigate();

	const { patientId, caseId, uuid } = useParams();
	const upperJawsIprData = Array.isArray(patientIPRJsonData) && patientIPRJsonData.filter(item => item.jaw === 'upper').length>0
	const lowerJawsIprData = Array.isArray(patientIPRJsonData) && patientIPRJsonData.filter(item => item.jaw === 'lower').length>0
	const handleClick = () => {
		setShowMessage(!showMessage);
	};
	const strapiUrl = "https://pacs-auth.dent-scan.com";
	useEffect(() => {
		if (globalState.isLoggedIn && globalState.addingIprDataBool) {
			globalState.setIsLoggedInForIpr(true);
			globalState.playPauseRef.current.style.display = "none";
		} else if (!globalState.isLoggedIn && globalState.addingIprDataBool) {
			globalState.setIsLoggedInForIpr(false);
			globalState.playPauseRef.current.style.display = "none";
			if (globalState.viewer) {
				if (globalState.viewer.scene) {
					while (
						globalState.viewer.scene.getObjectByName("iprGroup")
					) {
						globalState.actions.removeObjWithChildren(
							globalState.viewer.scene.getObjectByName("iprGroup")
						);
					}
				}
				globalState.actions.disposeThreeViewer(globalState.viewer);
			}
			globalState.setIsFileDropped(false);
			globalState.footerRef.current.style.display = "none";
		}
	}, [globalState.isLoggedIn, globalState.addingIprDataBool]);

	useEffect(() => {
		let jwtToken = cookies.get("jwtToken", { path: "/" });
		let userName = cookies.get("userName", { path: "/" });
		if (jwtToken) {
			globalState.setIsLoggedIn(true);
			if (userName) {
				// setUserName(userName);
				if (globalState.tempTreatmentData) {
					// let currentTreatmentPlan =
					// 	globalState.selectedTreatmentPlan - 1;
					let currentTreatmentPlan = globalState.tempTreatmentData.find(plan => parseInt(plan.attributes.treatment_plan_version) === globalState.selectedTreatmentPlan);

					let patientData = currentTreatmentPlan.attributes;
					setPatientName(patientData.patient_name);
					setPatientLowerData(patientData.lower_steps_number);
					setPatientUpperData(patientData.upper_steps_number);
					setPatientIPRData(patientData.ipr_data);
					setPatientIPRJsonData(patientData.ipr_data);		
					setDoctorName(patientData.doctor_name);
				}
			}
			userRole({ token: jwtToken });
		} else {
			globalState.setIsLoggedIn(false);
		}
	}, [globalState.tempTreatmentData, shareUrl, globalState.isLoggedIn, upperJawsIprData, lowerJawsIprData]);

	const getAttachment = () => {
		return (
			globalState.viewer?.scene?.getObjectsByProperty(
				"customType",
				"attachment"
			).length > 0
		);
	};
	const isAttachment = useMemo(() => getAttachment(), [globalState.viewer]);

	const userRole = async ({ token }) => {
		axios.get(`${strapiUrl}/api/users/me?populate=role`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (!res.data.blocked) {
					globalState.setTreatmentData(globalState.tempTreatmentData);
					// setIsLoginOpen(false);
					// appDivRef.current.classList.remove("overlay");
				}
				return res.data;
			})
			.catch((error) => {
				if(error.response.status===401){
					cookies.remove("jwtToken", { path: "/" });
					cookies.remove("userName", { path: "/" });
					cookies.remove("userRole", { path: "/" });
					globalState.setIsLoggedIn(false);
				}
			})

	};

	const treatmentPlanChange = (e) => {
		// treatmentUiId is the id of the selected treatment selected by the user
		let treatmentUiId = e.target.value;
		const selectedTreatmentData = globalState.treatmentData.find(
			(data) => data.attributes.treatment_plan_version === treatmentUiId
		);
		let url = selectedTreatmentData.attributes.link;
		if (process.env.NODE_ENV === "development") {
			url = `http://localhost:3000/${patientId}/${treatmentUiId}/${url.split("/").slice(-1)[0]
				}`;
		}
		// Redirect to new url
		window.location.replace(url);
	};

	const handleShareLink = () => {
		const payload = {
			patient_id: patientId,
			treatment_plan_version: caseId,
			uuid: uuid,
			exp: Math.floor(Date.now() / 1000) + process.env.REACT_APP_SHARE_LINK_EXPIRY_DAY * 24 * 3600, // Set expiration time to 1 hour from now 3600
		};
		const token = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(payload), process.env.REACT_APP_TOKEN_SECRET_KEY).toString());
		setShareUrl(`${window.location.origin}/share/${token}`)
	};

	const handleCopyClick = () => {
		let textArea = document.getElementById('shareUrl')
		textArea.select()
		document.execCommand('copy')
		textArea.blur()
		alert("Link copied to clipboard");
	};

	const handleLoginButton = () => {
		// globalState.setIsLoggedIn(true);
		setShowLogin(true);
		appDivRef.current.classList.add("overlay");

		setTimeout(() => {
			if (uuid) {
				globalState.viewer.onWindowResize(globalState.viewer);
			}
		}, 100);
	};

	const isLogout = async () => {
		cookies.remove("jwtToken", { path: "/" });
		cookies.remove("userName", { path: "/" });
		cookies.remove("userRole", { path: "/" });
		globalState.setIsLoggedIn(false);
		setShowLogin(false);
		setTimeout(() => {
			if (uuid) {
				globalState.viewer.onWindowResize(globalState.viewer);
			}
		}, 100);
		// appDivRef.current.classList.remove("overlay");
	};

	const handleHelpSubmit = () => {
		// console.log(baseUrl, patientId, caseId, uuid);
		let data = {
			doctorName: doctorName,
			patientName: patientName,
			treatmentPlanVersion: caseId,
			viewerUrl: `${baseUrl}/${patientId}/${caseId}/${uuid}`,
			message: helpFormData.message,
			mobileNumber: helpFormData.mobileNumber
		};
		// console.log(data)
		if(!globalState.isLoggedIn) return
		axios.post('https://notify.dent-scan.com/send-email', data, {
			headers: {
				'Content-Type': 'application/json',
				'api-key': '45dcd151-964f-4683-be0e-504de51c3011'
			}
		})
			.then((response) => {
				document.getElementById('closeModalButton').click();
				alert("Request sent. We will get back to you shortly");
			})
			.catch(error => {
				if(error.response.data.errors.length>0){
					alert(error.response.data.errors[0].msg);
				} else{
					alert("error sending email. Please try again later");
				}
			})
			.finally(() => {
				setHelpFormData({
					message: '', 
					mobileNumber: ''
				});
			});
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setHelpFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	return (
		<>
			<div className="HeaderNav-Right">
				<div className="HeaderNav-Right-List">
					<div className="HeaderNav-Right-Inner">
						{/* console.log({globalState.iprData}); */}
						{globalState.isLoggedIn && uuid && (
							<div className="HeaderNav-user">
								<div className="HeaderNav-userBox">
									<div className="HeaderNav-userBox__col HeaderNav-userBox__col--name">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Patient:{" "}
											</span>
											<span className="HeaderNav-userBox__value" title={patientName
													? patientName
													: "Unknown"}>
												{patientName
													? patientName
													: "Unknown"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Doctor:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{doctorName
													? doctorName
													: "Unknown"}
											</span>
										</div>
									</div>
									<div className="HeaderNav-userBox__col">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												IPR:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{patientIPRData ? "Yes" : "No"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Attachments:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{isAttachment ? "Yes" : "No"}
											</span>
										</div>
									</div>
									<div className="HeaderNav-userBox__col">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Upper:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{patientUpperData
													? patientUpperData
													: "N/A"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Lower:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{patientLowerData
													? patientLowerData
													: "N/A"}
											</span>
										</div>
									</div>
								</div>

								<div className="HeaderNav-download">
									<DownloadReport />
								</div>
							
							</div>
						)}
						<div className="HeaderNav-dropdown">
							<button
								className="toggle-btn"
								onClick={() => {
									setToggleBtnMenu(true);
									appDivRef.current.classList.add("overlay");
								}}
							>
								<img
									src={`${publicUrl}/assets/img/bar.svg`}
									alt="bar"
								></img>
							</button>
							{(globalState.isLoggedIn && uuid && (
								<select
									className="form-select"
									aria-label="Default select example"
									onChange={treatmentPlanChange}
									value={
										globalState.selectedTreatmentPlan || ""
									}
								>
									{globalState.treatmentData &&
										globalState.treatmentData.map(
											(item, index) => {
												let treatmentId =
													item.attributes
														.treatment_plan_version;
												return (
													<option
														key={index}
														value={treatmentId}
													>
														Treatment Plan{" "}
														{treatmentId}
													</option>
												);
											}
										)}
								</select>
							)) ||
								(uuid && (
									<div className="treatment-ipr-data">
										Treatment Plan {caseId}
									</div>
								))}
						</div>
						{globalState.isLoggedIn && uuid && (
							<>
								<div
								className="popup homeIcon" title="Home">
									<button className="HeaderNav-Msg-Btn" onClick={() => navigate('/', { replace: true })}>
										<i className="fa-solid fa-house"></i>
										<span className="shareText">Home</span>
									</button>
									
								</div>
								<div
									className="helpButton"
									// onClick={handleClick}
								>
									{patientIPRData && (
										<button className="HeaderNav-Msg-Btn showIprBtn"
											data-bs-toggle="modal"
											data-bs-target="#staticBackdropIPR"
										>
											{/* <i className="fa-solid fa-message btnIcon"></i> */}
											<i className="fa-solid fa-file btnIcon"></i>
											Show IPR
										</button>

									)}
									<button className="HeaderNav-Msg-Btn"
										data-bs-toggle="modal"
										data-bs-target="#staticBackdropHelp"
										id="closeModalButton"
									>
										<i className="fa-solid fa-message btnIcon"></i>
										Contact us
									</button>
									{showMessage && (
										<span className="popuptext" id="myPopup">
											Lorem Ipsum is simply dummy text of the
											printing and typesetting industry. Lorem
											Ipsum has been the industry's standard
											dummy text ever since the 1500s, when an
											unknown printer took a galley of type
											and scrambled it to make a type specimen
											book. It has survived not only five
											centuries, but also the leap into
											electronic typesetting, remaining
											essentially unchanged. It was
											popularised in the 1960s with the
											release of Letraset sheets containing
											Lorem Ipsum passages, and more recently
											with desktop
											<p className="date-meg">
												Dent scan 18/09/2023 16:20
											</p>
										</span>
									)}
								</div>
								<div
									className="modal fade"
									id="staticBackdropHelp"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropHelpLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>
											
											<div className="modal-body">
												<input
													type="text"
													id="helpPhone"
													name="mobileNumber"
													className="form-control mb-2"
													placeholder="Mobile Number"
													value={helpFormData.mobileNumber}
													onChange={handleInputChange}
												/>
												<textarea
													id="helpMessage"
													name="message"
													className="form-control"
													placeholder="Message"
													value={helpFormData.message}
													onChange={handleInputChange}
												></textarea>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="button"
													onClick={handleHelpSubmit}
												>
													Send
												</button>
											</div>
										</div>
									</div>

								</div>

								<div
									className="modal fade"
									id="staticBackdropIPR"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropHelpLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>


											<div className="modal-body">
												<div>
													{(upperJawsIprData) ? (
														<>
															<h6>Upper Jaw</h6>
															<table className="table">
																<thead>
																	<tr>
																		<th>Sr. No</th>
																		<th>Jaw</th>
																		<th>Note</th>
																		<th>Step No.</th>
																		<th>Value</th>
																		<th>Between</th>
																	</tr>
																</thead>
																<tbody>
																	{patientIPRJsonData &&patientIPRJsonData.filter(item => item.jaw === 'upper').map((item, index) => (
																		<tr key={index}>
																			<td>{index + 1}</td>
																			<td>{item.jaw}</td>
																			<td>{item.note}</td>
																			<td>{item.step_no}</td>
																			<td>{item.value}</td>
																			<td>{item.between}</td>
																		</tr>
																	)) }

																</tbody>
															</table>
														</>
													): (
														<h6>Upper Jaw - No Data Available</h6>
													)}
													<br></br>
													{(lowerJawsIprData) ?
													(<><h6>Lower Jaw </h6>
													<table className="table">
														<thead>
															<tr>
																<th>Sr. No</th>
																<th>Jaw</th>
																<th>Note</th>
																<th>Step No.</th>
																<th>Value</th>
																<th>Between</th>
															</tr>
														</thead>
														<tbody>
															{patientIPRJsonData && patientIPRJsonData.filter(item => item.jaw === 'lower').map((item, index) => (
																	<tr key={index}>
																		<td>{index + 1}</td>
																		<td>{item.jaw}</td>
																		<td>{item.note}</td>
																		<td>{item.step_no}</td>
																		<td>{item.value}</td>
																		<td>{item.between}</td>
																	</tr>
															))}
														</tbody>
													</table>
													</>):<h6>Lower Jaw - No Data Available</h6>}
												</div>
											</div>

										</div>

									</div>
								</div>



							</>
						)}
						{globalState.isLoggedIn && uuid && (
							<>
								<div className="HeaderNav-share">
									<button
										onClick={handleShareLink}
										className="share-btn"
										data-bs-toggle="modal"
										data-bs-target="#staticBackdrop"
										title="Share"
									>
										<i className="fa-solid fa-share-nodes btnIcon"></i>
										<span className="shareText">Share</span>
									</button>
								</div>
								<div
									className="modal fade"
									id="staticBackdrop"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>
											<div className="modal-body">
												<textarea id="shareUrl" className="copyUrlTextarea" readOnly value={shareUrl}></textarea>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="button"
													onClick={handleCopyClick}
												>
													Copy
												</button>
												<a href={shareUrl} target="_blank" rel="noopener noreferrer">
													<button type="button" className="button" >Open</button>
												</a>
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						{globalState.isFileDropped && (
							<div className="HeaderNav-addipr">
								<button
									ref={globalState.addIprButtonHeaderRef}
									onClick={() =>
										globalState.actions.activateAddIpr(
											globalState
										)
									}
									className="HeaderNav-addipr__btn"
								>
									Add IPR
								</button>
								<button
									onClick={() =>
										globalState.actions.generateJsonData(
											globalState
										)
									}
									className="HeaderNav-addipr__btn"
								>
									Generate Json Data
								</button>
							</div>
						)}

						<div className="HeaderNav-auth">
							{globalState.isLoggedIn ? (
								<>
									<div
										className={`HeaderNav-auth__welcome ${uuid ? "uuid" : ""
											}`}
									>
										Welcome,
										<span className="HeaderNav-auth__username">
											{globalState.nameOfUser??"N/A"}
										</span>
									</div>

									<button
										onClick={isLogout}
										className="HeaderNav-auth__logout"
									>
										<i className="fa-solid fa-arrow-right-from-bracket btnIcon"></i>
										Logout
									</button>
								</>
							) : (
								<>
									<button
										className="HeaderNav-auth__login"
										onClick={handleLoginButton}
									>
										<i className="fa-solid fa-arrow-right-to-bracket btnIcon"></i>
										Login
									</button>

									{showLogin && (
										<Login appDivRef={appDivRef} loginType="header"/>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
